@import "../../theme/colours.scss";

.drop-detail-card {
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  padding: 1.75rem 1.313rem 0.5rem 1.313rem !important;
  height: 25.5rem;

  &.extra-height {
    height: 30.5rem;
  }
}

.divider {
  border-bottom: 1px solid #D1D1D1;
}

.drop-detail-label-name, .drop-detail-label-value {
  font-size: 0.813rem;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.drop-detail-label-name {
  color: $bp-gray;
  overflow-wrap: anywhere;
}

.drop-detail-label-value {
  color: $bp-black;
  overflow-wrap: anywhere;
}

.edit-col {
  text-align: right;
}

.trash-icon {
  color: $secondary-green;
}

.trash-icon-container {
  padding: 0.5rem 0.75rem;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: $palest-green;
  }
}