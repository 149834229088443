@import "../../theme/colours.scss";

.secondary-button {
  background-color: #fff !important;
  border-radius: 5px;
  width: 100%;
  height: 2.875rem;
  border: 1px solid $primary-green !important;
  color: $bp-black !important;
}

.secondary-button:disabled, .secondary-button:hover:enabled {
  color: rgba($bp-black, 0.6) !important;
  border-color: rgba($secondary-green, 0.6) !important;
}
