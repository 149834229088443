@import "../../theme/colours.scss";

.load-id-addon-row {
  display: flex;
  flex-direction: row;
}

.load-id-addons-container {
  margin-top: 1.5rem;
}

.title-container {
  margin-bottom: 2.5rem;
  font-family: inherit;
}

.load-id-addon-index-container {
  height: 2.5rem;
  width: 2.5rem;
  background-color: $pale-green;
  border-radius: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.load-id-index {
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.load-id-addon {
  color: $secondary-green;
  margin-left: 1.5rem;
}