@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";
@import "../../theme/fonts.scss";

.page-heading-row {
  margin-top: 60px;
}
.welcome {
  margin-top: 40px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.page-heading {
  margin-top: 3.125rem;
  margin-bottom: 2rem;
  line-height: 2.125rem;
}

.sub-heading {
  margin-top: 2.813rem;
}

.box {
  width: 8.25rem;
  height: 7.75rem;
  background: $white;
  border: 1px solid $secondary-gray;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.boxes-col {
  @media screen and (min-width: $tablet) {
    margin-top: 1.25rem;      
  }  
}

.box-col {
  margin-left: 0.594rem;
  margin-right: 0.594rem;

  @media screen and (min-width: $tablet) {
    margin-left: 1.875rem;
    margin-right: 1.875rem;        
  }  

  @media screen and (max-width: 374px) {
    margin-left: 0.188rem;
    margin-right: 0.188rem;
  }
}

.box-text {
  text-align: center;
  color: $primary-green;
  line-height: 26px;
}

.search-icon {
  color: $primary-green;
}

.file-icon {
  color: $primary-green;
}

.help-text {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.1px;
  margin-left: 0.313;
}

.signout-btn {
  width: 50% !important;
}

.info-icon {
  display: inline-block;
  margin-top: 0rem;

  // Responsive icon alignment
  @media screen and (max-width: 358px){
      margin-top: 0.5rem;    
  } 
}

.timeout-text {
  text-align: center;
  justify-content: center;  
} 
  
