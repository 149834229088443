@import "./theme/colours.scss";
@import "./theme/fonts.scss";

html {
  height: 100%;
}

.awsappsync, .awsappsync > div {
  height: 100%;
}

body {
  margin: 0;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  height: 100%;
  background-color: #FCFCFC;
}

#root {
  height: 100%;
}

input {
  height: 2.875rem !important;

  &.is-invalid {
    border-color: $alert-red !important;
  }

  &.is-invalid:focus {
    box-shadow: none !important;
  }

  &:disabled {
    background-color: #FFF !important;
    color: #C2C2C2;
    cursor: default;
  }
}

select {
  cursor: pointer;
  font-weight: 300 !important;
}

select:disabled {
  background-color: #FFF !important;
  color: #C2C2C2 !important;
  cursor: default;
  border-color: #DEDEDE;
}

.add-pointer {
  cursor: pointer;
}

.green-link {
  color: $secondary-green;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.313rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.half-opacity {
  opacity: 0.5;
  cursor: no-drop;
}

// -------------- Color --------------
.green {
  color: $secondary-green;
}

// -------------- Border --------------
.border-primary-green {
  border: 1px solid $primary-green;
}

// -------------- Alerts --------------
.alert-warning svg {
  color: $warm-yellow;
}