@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";
@import "../../theme/fonts.scss";

.welcome-heading {
    text-align: center;
    padding-top: 2.813rem;
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 300;

    @media screen and (max-width:$tablet) {
        font-size: 1.625rem;
        padding-top: 2.563rem;
    }
    @media screen and (min-width:$tablet) {
        font-size: 2rem;
    }
}

.welcome-container {
    display: flex;
    align-items: flex-start;
}

.app-info {
    text-align: center;
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 200;   
       

    @media screen and (max-width:767px) {
        margin-top: 2.125rem;
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-bottom: 3.25rem;
        padding-left: 2.063rem;
        padding-right: 2.063rem;
    }

    @media screen and (min-width:$tablet) {
        margin-top: 3.438rem;
        font-size: 1.438rem;
        line-height: 2.125rem;
        margin-bottom: 5.5rem;
    }
}

.card-container{
    background: white;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 100%;

    @media screen and (min-width:$tablet) {
        margin-bottom: 7.5rem;
    }

    @media screen and (max-width:$tablet) {
        margin-bottom: 4.875rem;
    }
}

div.card-container.row {
    margin-left: 0px;
    margin-right: 0px;
}

.card {
    width: 14rem;
    height: 10rem;
    background: $card-grey !important;
    position: relative;  
}

.card-col {
    display: grid;
    justify-content: center;
}

.icon1 {
    background-image: url(../../images/wel-3.png);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon2 {
    background-image: url(../../images/wel-2.png);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon3 {
    background-image: url(../../images/wel-1.png);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon-text {
    width: 14rem;
    height: 4.688rem;
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 200; 
    font-size: 1rem;
    line-height: 1.625rem;
    text-align: center;
    margin-top: 2.375rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 3rem;
        margin-top: 1rem;
        height: 4.5rem;        
    }
}

@media screen and (max-width: $tablet){
    .sign-in-row {
        position: sticky;   
        bottom: 0; 
        padding-bottom: 1.75rem;   
        padding-top: 1.75rem;        
    }

    .sign-in-row.sticky {
        background-color: white;
        filter: drop-shadow(0px -2px 16px rgba(0, 0, 0, 0.25));
    }

    .sign-in-row.blended {          
        background-color: transparent;       
    }

}

.help-text-col {
    display: flex;
    justify-content: center;  

    @media screen and (max-width: $mobile) {
        padding-left: 95px !important; 
        padding-right: 100px !important;
    }
}

.example-text {
    font-family: Arial;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.006rem;
    color: $bp-black;
    display: block; 
    overflow: hidden;
    margin-top: 0;
    padding-left: 0.5rem;

    @media screen and (max-width: $mobile){
        margin:0;
    }
}


.info-icon {
    display: inline-block;
    margin-top: 0rem;

    // Responsive icon alignment
    @media screen and (max-width: 358px){
        margin-top: 0.5rem;    
    } 

}

#welcome-spinner {
    width: '3rem';
    height: '3rem';
}

#error-message {
    font-size: $font23;

    @media screen and (max-width:$tablet) {
        font-size: $font18;
    }
}

.graphic {    
    width: 100%;
    height: 100%;
}

.bold {
    font-weight: 900;
}
