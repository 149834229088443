@import 'react-loading-skeleton/dist/skeleton.css';
@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";


// TODO - Make this element a flex
.ship-to {
    background: $light-green!important;
    @media screen and (min-width: $tablet){
        margin-bottom: 2rem;
    }  
}
