// -------------- Font Sizes --------------
$font18: 1.125rem;
$font23: 1.4375rem;

.font-12 {
  font-size: .75rem;
}
  
// Approx 13px font size
.font-13 {
  font-size: .8125rem;
}

// Approx 14px font size
.font-14 {
  font-size: .875rem;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: $font18;
}

.font-23 {
  font-size: $font23;
}

.font-30 {
  font-size: 1.8rem;
}

.font-32 {
  font-size: 2rem;
}

.lightweight {
  font-weight: 300;
}

.line-height-20 {
  line-height: 1.25rem;
}

// -------------- Font Families --------------
.arial {
  font-family: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.univers {
  font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.roboto {
  font-family: Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}