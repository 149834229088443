@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

// NAVBAR
.navbar {
  background: white;
  box-shadow: 0px 2px 4px rgba(23, 27, 30, 0.1);
  padding-bottom: 0.625rem;

  @media screen and (max-width: $tablet) {
    height: 3.375rem;
    padding-left: 0.625rem !important; // subtract the extra space around burger menu
    padding-right: 1.25rem !important;
  }

  @media screen and (min-width: $tablet) {
    height: 4.375rem;
    padding-left: 1.063rem !important; // subtract the extra space around burger menu
    padding-right: 2rem !important;
  }
}

a.navbar-brand {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
  color: #000000;
  
  @media screen and (max-width: $tablet) {
    padding-top: 10px;
    margin-bottom: 0.688rem;
    font-size: 1.25rem;
  }

  @media screen and (min-width: $tablet) {
    margin-top: 0.938rem;
    margin-bottom: 0.938rem;
    font-size: 1.4375rem;
  }
}

.logo-container {
  @media screen and (max-width: $tablet) {
    margin-top: 0.1rem;
  }
}

.navbar-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media screen and (max-width: $tablet) {
    background-image: url(../../images/bp-logo-small.png);
    width: 2rem;
    height: 2.625rem;
  }

  @media screen and (min-width: $tablet) {
    background-image: url(../../images/bp-logo.png);
    width: 2.625rem;
    height: 3.563rem;
  }
}

// FOOTER
.footer {
  margin-top: 1rem;
  padding: 1.938rem 1.563rem 1.938rem 1.563rem;
  background: $light-grey;
  width: 100%;
}

.footer-link {
  font-family: Arial;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.006rem;
  color: rgba($bp-black, 0.7);
}

.footer-link:first-child {
  @media screen and (max-width: $tablet){
    margin-bottom: 1.25rem;
  }
}

a {
  text-decoration: none !important;
  color: rgba($bp-black, 0.7) !important;

  &:hover {
    text-decoration: underline !important;
    color: rgba($bp-black, 0.7);
  }
}

// PAGE CONTENT
.layout-container {
  display: flex;
  flex-direction: column;
  background-color: $ui-shell-grey;
}

.main-content {
  @media screen and (max-width: $tablet) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  @media screen and (min-width: $tablet) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}