@import "../../theme/colours.scss";

.link-tile-container {
    background-color: $white;
    
    &.void {
        background-color: $void-red;
        border: 1px solid $void-red-border;
        &:hover {
            border: 1px solid $void-red-border;
            cursor: no-drop;
        }
    }
    &.completed {
        border: 1px solid #2EE68C;
        border-radius: 4px;
        background-color: #E8FAF1;
    }
    &.disabled {
        opacity: 0.5;
        cursor: no-drop;
        &:hover {
            border: 1px solid $secondary-gray;
            cursor: no-drop;
        }
    }
    border: 1px solid $secondary-gray;
    &:hover {
        border: 1px solid $bp-black;
        cursor: pointer;
    }
}

.check {
    @media screen and (max-width: 415px) {
        margin-top: -0.938rem
    }
}

.lt-line {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;

    @media screen and (max-width: 415px) {
        width: 6.25rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }   
}