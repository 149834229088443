@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.sidebar {
    height: 100%;
    background: $white;
    position: fixed;
    left: 0;
    z-index: 200;
    transition: .5s; 
    width: 0;
    overflow: hidden;
    
    @media screen and (max-width: $tablet) {
        margin-top: 3.75rem;
        box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: $tablet) {
        margin-top: 5rem;
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    }
}

.sidebar-close {
    width: 0rem;
}

.sidebar-tablet {
    width: 15.5rem;
}

.sidebar-mobile {
    width: 100%;
}

.sidebar ul li {
    list-style-type: none !important;

    &:hover {
        background: rgba($primary-green, 0.8) !important;
        text-decoration: none !important;
        & button {
            color: white !important;
        }
    }
}

// DefaultLayout styling
.navbar-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
.navbar-items {
    text-decoration: none;
}

.x-button {
    color: $primary-green;
}

.hamburger-container {
    color: $bp-black;
}

#navbar-icon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.sidebar-link {
    font-size: 0.875rem;
    color: $sidebar-gray;
    background: none;
    border: none;

    &:hover {
        color: white !important;
    }
}