// DELIVERED STATUS INDICATOR
.delivered-alert {
  padding-top: 7px;
  padding-bottom: 7px;
}

.alert-icon {
  margin-top: -1px;
}

.alert-label {
  line-height: 2rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.order-type-label {
  line-height: 2.125rem;
}

.alert-dismissible .close {
  height: 100%;
}