@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.content {
    position: relative;
}

.page-heading {
    margin-top: 4.875rem;
}

.form-title {
    margin-top: 4.875rem;
    font-size: 23px;
    font-weight: 300;
}

.shipment-header {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #111111;
}

.input-field {
    margin-top: 0.75rem;
}

// Help text for input field
.text-help, .text-error {
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 1rem;
    letter-spacing: 0.031rem;
}

.text-help {
    color: $bp-gray;
}

.text-error {
    color: $alert-red;
}

.go-button {
    margin-top: 2.85rem;
}

// Divider in tablet view
.line {
    @media screen and (min-width:$tablet) {
        border: 1px solid $primary-gray;
        margin-top: 6rem;
    }   
}

.graphic-help {
    margin-top: 7.5rem;
    @media screen and (min-width:$tablet) {
        margin-top: 2.969rem;
    }
}

// Load instruction graphic
.graphic {    
    object-fit: cover;      
}


// Load instruction graphic info text
.text {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.006rem;
    color: $bp-black;
    margin-left: 5px;
}

.online-mode-help {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $bp-black;
  }

.online-mode-help {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $bp-black;
  }





