@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.dropDownResults {
    max-height: 330px;
    overflow-y: scroll;
    transform: translate3d(0px, 3.5em, 0px) !important;
}

.new-address {
    height: 16rem;  
    min-height: 16rem;
    background: $light-green!important;
    border-radius: 0.25rem;
    margin-bottom: 1rem;  
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $tablet){
        margin-bottom: 2rem;
    }  
}

.no-address-label {
    text-align: center;
}

.clear-address {
    color: $secondary-green;
}

.remove-styling {
    background-color: transparent;
}

.shipToSearchLabel {
    font-size: 0.75rem;
    letter-spacing: 0.08rem;
}

.input-group-append span {
    padding-bottom: 1.6em;
    padding-top: 1.55em;
}

.ship-to-details {
    display: block;
}
