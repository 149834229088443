.back-button-container {
    margin-top: 1.5rem;
}

.button-text {
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.625rem;
    cursor: pointer;
    
}

.icon {
    display: flex;
    align-items: center; 
}