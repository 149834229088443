@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.drop-details-form-title {
  margin-top: 2.25rem;
}

.drop-details-form-subtitle {
  font-size: 0.813rem;
  line-height: 1.25rem;
  color: $bp-black;
}

.optional {
  font-style: italic;
}

.tablet-divider {
  @media screen and (min-width:$tablet) {
      border: 1px solid $primary-gray;
      margin-bottom: 3rem;
  }   
}

.text-warn {
  color: $alert-red;
}

.qty-ambient {
  color: $bp-black;
}

.vehicle-row {
  font-size: 0.875rem;
}

.vehicle-no {
  font-size: 0.9375rem;
}

.input-colour {
  background-color: $white !important;
}