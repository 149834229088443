$primary-green: #007F00;
$secondary-green: #006600;
$pale-green: #E4F1E5;
$palest-green: #EFF7EF;
$alert-red: #A80000;
$bp-black: #111111;
$bp-gray: #666666;
$primary-gray: #D8D8D8;
$secondary-gray: #DEDEDE;
$light-green: #EAF4EA;
$light-grey: #F2F2F2;
$ui-shell-grey: #FCFCFC;
$white: #FFFFFF;
$card-grey: #FAFAFA;
$sidebar-gray: #252530;
$void-red-border: #FF7591;
$void-red: #FFE4E9;
$warm-yellow: #FFCB2E;