@import "../../theme/colours.scss";

.products-container {
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(35, 35, 35, 0.2);
  border-radius: 8px;
}

.divider {
  border-bottom: 1px solid #D1D1D1;
  margin-bottom: 2rem;
}

.load-id-addon-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.product-label-name, .product-label-value {
  font-size: 0.813rem;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.product-label-name {
  color: $bp-gray;
}

.product-label-value {
  color: $bp-black;
}