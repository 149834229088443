@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";
@import "../../theme/fonts.scss";
@import 'react-loading-skeleton/dist/skeleton.css';

.details-container-LP {
    background: $light-grey;
    @media screen and (min-width: $tablet){
        margin-left: 1rem;
    }
}

.details-container-RD {
    background: $light-grey;
    @media screen and (min-width: $tablet){
        margin-left: 1rem;
    }
}

.instruction-box {
    background: $light-grey;
}

.sigCanvas {
    border: 2px solid BLACK;
}

.sigCanvas-disabled {
    border: 2px solid $secondary-gray
}

.date-icon{
    min-width: 32px;
    min-height: 32px;
    margin-top: -0.5rem;
    margin-right: 1rem;
}

.driver-icon{
    min-width: 32px;
    min-height: 32px;
    margin-top: -0.5rem;
    margin-right: 1rem;
    @media screen and (min-width: $tablet){
        margin-left: 1rem;
    }
}

.instruction-icon{
    min-width: 48px;
    min-height: 48px;
    margin-top: -1rem;
}