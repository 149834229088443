@import "../../theme/colours.scss";

.primary-button {
    background-color: $primary-green !important;
    border-radius: 5px;
    width: 100%;
    height: 46px;
    border: none !important;
    text-align: center !important;
}

.primary-button:disabled {
    background: rgba($primary-green, 0.5) !important;
} 
    
.primary-button:hover:enabled {
    background-color: $secondary-green !important;
}
