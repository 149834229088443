@import "../../theme/colours.scss";
@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";
@import "../../theme/fonts.scss";
@import 'react-loading-skeleton/dist/skeleton.css';

.lids-container {
  background:$light-grey;
  border-radius: 10px;
}

.product-list{
  height: 180px;
  width: auto;
}

.page-selected {
  height: 8px;
  width: 8px;
  background-color:green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.page-unselected {
  height: 8px;
  width: 8px;
  background-color:grey;
  opacity: 0.5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

button.btn-outline-light{
  color: black;
  border-color: #007F00;
  border-radius: 0%;
  background-color: white;
}

button.btn-outline-light:hover{
  color: black;
  border-color: #007F00;
}

button.btn-outline-light:focus{
  color: white;
  background-color: #007F00;
  border-color: #007F00;
}

button.btn-light{
  color: white;
  background-color: #007F00;
  border-color: #007F00;
}


button.btn-light:hover{
  color: white;
  background-color: #007F00;
  border-color: #007F00;
}

button.btn-light:focus{
  color: white;
  background-color: #007F00;
  border-color: #007F00;
}

.page-inactive{
  cursor: pointer;
  color: grey;
  opacity: 0.5;
}

.page-active{
  cursor: pointer;
  color: green;
}

.load-id-addon-label {
  font-size: 12px;
  line-height: 1.5rem;
}

.product-label-name, .product-label-value {
  font-size: 12px;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.product-label-name {
  color: black;
  
  @media screen and (min-width: 420px) and (max-width: 500px) {
    margin-left: 10px !important;
  }
  @media screen and (min-width: 501px) and (max-width: 539px) {
    margin-left: 40px !important;
  }
  @media screen and (min-width: 540px) and (max-width: 609px) {
    margin-left: 60px !important;
  }
  @media screen and (min-width: 610px) and (max-width: 767px) {
    margin-left: 80px !important;
  }
}

.product-label-value {
  color: black;
}

.info-icon{
  min-width: 32px;
  min-height: 32px;
  margin-top: -0.5rem;
}

.pageNumbers{
  list-style: none;
  display: flex;
}

.pageNumbers li.active{
  background-color: lightgreen;
}

.load-id-text{
  color: #007F00;
  font-size: 12px;
  text-align: center;
}

.customized-font{
  font-size: 13px;
  color: rgba(17,17,17,0.645)
}

.validation-info {
  line-height: 18px;
}

