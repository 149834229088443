@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.geo-btn{
    color: black;
    background-color: white;
    border-color: $primary-green;
    border-style: solid;  
    border-radius: 5px;
    height:46px;
    width:288px;
}
.warning-btn{
    color: black;
    background-color: #E8FAF1;
    border-color: #2EE68C;
    border-style: solid;  
    border-radius: 5px;
    height:46px;
    width:288px;
}

.message-btn{
    color: black;
    background-color: #FDFCDD;
    border-color: #FFEE5C;
    border-style: solid;  
    border-radius: 5px;
    width:288px;
    padding: 10px
}

.info-btn{
    color: black;
    background-color: white;
    border-color: white;
    border-style: solid;  
    border-radius: 0.5px;
    height:56px;
    width:288px;
}

.font-customized{
    color:$bp-gray;
    font-size: 14px;
}

.padding-section1{
    @media screen and (max-width: $mobile){
        height: 98px;
    }
    height: 59px;
}

.padding-section2{
    @media screen and (max-width: $mobile){
        height: 48px;
    }
    height: 30px;
}

.padding-section3{
    @media screen and (max-width: $mobile){
        width: 13px;
    }
    width: 68px;
}