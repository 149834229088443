@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";

.back-button {
  padding-top: 33px;
}

.find-open-order-alert {
  @media screen and (min-width: 654px) {
    margin: 0px !important;
  }
  @media screen and (max-width: 409px) {
    margin-bottom: 40px !important;
  }
}

.form-title {
    margin-top: 4.875rem;
    font-size: 23px;
    font-weight: 300;
}
