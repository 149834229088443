@import "../../theme/colours.scss";
@import "../../theme/mediaSizes.scss";


._1alIC {
    left: 10%;
    right: 10%;
    color: black;
    width: 80%;
    top: 5%;
    display: block;
    width: 100%;
    border: 2px solid #4FC3F7;
    padding: 11px;
    background-color: #ECFAFE;
    border-radius: 5px;
    display: flex;
    z-index: 9999;
    flex-direction: column;
}

.no-margin{
    margin-bottom: 0px;
}
