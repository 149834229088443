@import "../../../theme/colours.scss";
@import "../../../theme/mediaSizes.scss";

.content-row {
    @media screen and (max-width:$tablet){
        padding-left: 1.688rem;
        padding-right: 1.688rem;
    }
}

.heading {
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 300;
    font-size: 2rem;
    line-height: 3rem;

    @media screen and (max-width: $tablet) {
        margin-top: 5.875rem;
        font-size: 1.625rem;
    }

    @media screen and (min-width: $tablet) {
        margin-top: 4.438rem;
    }
}

.sub-heading {
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 300;
    font-size: 1.438rem;
    line-height: 2.125rem;

    @media screen and (max-width: $tablet) {
        margin-top: 4.5rem;
    }

    @media screen and (min-width: $tablet) {
        margin-top: 4.875rem;
    }
}

.content {
    font-family: "Univers", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", 
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.625rem;
    margin-top: 2.063rem;
}