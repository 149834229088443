@import "../../theme/mediaSizes.scss";

.bol-graphic {
  max-width: 84px;
}

.bol-arrow-right {
  margin-left: 12px; // width of arrow top
  margin-top: -6px;
}

.icon-level {
  margin-top: 8px;
}

.info-icon-open-order {
  display: inline-block;
  margin-top: 7px !important;
}

// File upload input
#file-upload-input {
  display: none;
}
.custom-file-upload {
  line-height: 26px !important;
}

.status-msg-container {
  height: 18px;
}

// Photo preview
.photo-preview-container {
  max-width: inherit;
  margin-top: 8px;
}

.photo-preview-container > img {
  max-width: 100%;
  object-fit: contain;

  @media screen and (max-width: $tablet) {
    max-height: 175px;
  }

  @media screen and (min-width: $tablet) {
    max-height: 276px;
  }
}
